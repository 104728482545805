@font-face {
    font-family: Lexend;
    font-weight: 900;
    src: url("assets/lexend/Lexend-Black.ttf");
}

@font-face {
    font-family: Lexend;
    font-weight: bolder;
    src: url("assets/lexend/Lexend-ExtraBold.ttf");
}

@font-face {
    font-family: Lexend;
    font-weight: bold;
    src: url("assets/lexend/Lexend-Bold.ttf");
}

@font-face {
    font-family: Lexend;
    font-weight: medium;
    src: url("assets/lexend/Lexend-Medium.ttf");
}

@font-face {
    font-family: Lexend;
    src: url("assets/lexend/Lexend-Regular.ttf");
}

@font-face {
    font-family: Lexend;
    font-weight: 600;
    src: url("assets/lexend/Lexend-SemiBold.ttf");
}

@font-face {
    font-family: Lexend;
    font-weight: 400;
    src: url("assets/lexend/Lexend-Light.ttf");
}

@font-face {
    font-family: Lexend;
    font-weight: 300;
    src: url("assets/lexend/Lexend-ExtraLight.ttf");
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
/* hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* hide scrollbar for IE and Edge */
body {
    -ms-overflow-style: none;
}

/* hide scrollbar for Firefox */
body {
    scrollbar-width: none;
}

/* hide scrollbars chrome */
::-webkit-scrollbar {
    display: none;
}
