body {
    background-color: #202127;
}

body.overlay {
    background-color: transparent !important;
    max-height: 100% !important;
    overflow: hidden !important;
}

img {
    pointer-events: none;
}

.page-anim-enter {
    opacity: 0;
    transform: translate(-2rem, 0);
    transition: opacity ease 0.125s, transform ease 0.25s;
}

.page-anim-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity ease 0.125s, transform ease 0.25s;
}

.page-anim-exit {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity ease 0.125s, transform ease 0.25s;
}

.page-anim-exit-active {
    opacity: 0;
    transform: translate(2rem, 0);
    transition: opacity ease 0.125s, transform ease 0.25s;
}

/** POPUP **/

.popup-anim-enter {
    opacity: 0;
    transform: translate(0, 2rem);
}

.popup-anim-enter-active {
    opacity: 1;
    transform: translate(0, 0);
}

.popup-anim-exit {
    opacity: 1;
    transform: translate(0, 0);
}

.popup-anim-exit-active {
    opacity: 0;
    transform: translate(0, 2rem);
}

.fade-anim-enter {
    opacity: 0;
    position: absolute;
    width: calc(100vw - 4rem);
}

.fade-anim-enter-active {
    opacity: 1;
    position: absolute;
    width: calc(100vw - 4rem);
}

.fade-anim-exit {
    opacity: 1;
    position: absolute;
    width: calc(100vw - 4rem);
}

.fade-anim-exit-active {
    opacity: 0;
    position: absolute;
    width: calc(100vw - 4rem);
}

ul li {
    color: #fff;
    list-style-type: none;
    margin-left: 0;
    font-family: "Lexend", Arial, Helvetica, sans-serif;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* hide scrollbar for IE and Edge */
body {
    -ms-overflow-style: none;
}

/* hide scrollbar for Firefox */
body {
    scrollbar-width: none;
}
